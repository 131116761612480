export const allergeni = [
    { key: 'glutine', text: 'Cereali contenenti glutine', value: 'glutine' },
    { key: 'crostacei', text: 'Crostacei', value: 'crostacei' },
    { key: 'uova', text: 'Uova', value: 'uova' },
    { key: 'pesce', text: 'Pesce', value: 'pesce' },
    { key: 'arachidi', text: 'Arachidi', value: 'arachidi' },
    { key: 'soia', text: 'Soia', value: 'soia' },
    { key: 'latte', text: 'Latte', value: 'latte' },
    { key: 'frutta_a_guscio', text: 'Frutta a guscio', value: 'frutta_a_guscio' },
    { key: 'sedano', text: 'Sedano', value: 'sedano' },
    { key: 'senape', text: 'Senape', value: 'senape' },
    { key: 'semi_di_sesamo', text: 'Semi di sesamo', value: 'semi_di_sesamo' },
    { key: 'anidride_solforosa', text: 'Anidride solforosa e solfiti', value: 'anidride_solforosa' },
    { key: 'lupini', text: 'Lupini', value: 'lupini' },
    { key: 'molluschi', text: 'Molluschi', value: 'molluschi' },
  ];